<template>
  <div>
    <v-dialog v-model="downloadPopup" max-width="600">
      <v-card class="pa-8">
        <div class="object__item">
          <div class="title center-align mb-4">Выберите период</div>
          <div class="d-flex justify-space-between">
            <div class="ma-2">
              <DatePicker v-model="moment_gte" label="Дата начала периода" />
            </div>
            <div class="ma-2">
              <DatePicker v-model="moment_lte" label="Дата окончания периода" />
            </div>
          </div>
        </div>
        <v-card-actions class="mt-4 d-flex justify-end">
          <v-spacer></v-spacer>
          <v-btn class="btn btn_color_white" @click="downloadPopup = false">
            ОТМЕНА
          </v-btn>
          <v-btn
            :loading="dialog"
            :disabled="
              !this.moment_gte ||
                !this.moment_lte ||
                this.moment_gte > this.moment_lte
            "
            class="btn btn_color_green"
            @click="dowloadPeriodPassLog()"
          >
            ПРОДОЛЖИТЬ
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" hide-overlay persistent width="600" height="400">
      <v-card color="#95c23d" dark>
        <v-card-text>
          <div class="title title-loading">
            Ожидайте выгрузки файла, который будет доступен в разделе меню
            <router-link style="color: #FFFFFF" :to="{ name: 'Profile' }" exact
              >"Профиль"</router-link
            >
          </div>

          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <TableFilters :title-count="sourceObject.count" :queries="queries">
      <template v-slot:filter-title>
        <div v-if="todayLogsInfo(sourceObject).logsCounts > 0">
          <div style="display:flex; flex-direction: row; align-items: center;">
            <div class="mr-10">
              Проходов: {{ todayLogsInfo(sourceObject).logsCounts }}
            </div>
          </div>
        </div>
        <div v-if="todayLogsInfo(sourceObject).logsCounts == 0" class="">
          <span class="title-filter">Проходов: </span>{{ sourceObject.count }}
        </div>
        <!--        <span class="sub-title">проходов</span>-->
      </template>

      <template v-slot:download-button>
        <v-btn
          @click="downloadList"
          class="btn btn_color_grey btn-unload flex-right mr-4"
          :disabled="loadingBtn || sourceObject.results.length == 0"
          :loading="loadingBtn"
        >
          Выгрузить
        </v-btn>
      </template>
    </TableFilters>
    <TableLayout
      :loading="isDateLoaded"
      disable-router
      :header="header"
      :source-object="sourceObject"
      :table-data="sourceObject.results"
    />
  </div>
</template>

<script>
import DatePicker from "@/components/fields/DatePicker";

import TableFilters from "@/components/tables/TableFilters";
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import moment from "moment";
import tableColsPassageLog from "@/helpers/tableCols/passageLog";
export default {
  name: "KindergartensPassageLog",
  components: { TableLayout, TableFilters, DatePicker },
  data() {
    return {
      loadingBtn: false, // загрузка страницы
      queries: query.passLogCards, // параметры фильтра с helpers query
      downloadPopup: false, // окно скачивания
      dialog: false, // окно полосы загрузки
      objectId: null, // id объекта ДС
      moment_lte: null, // конец периода
      moment_gte: null // начало периода
    };
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: {
        ...this.urlQuery.query,
        //"days-offset": 1,
        moment__gte: moment()
          .add(-1, "days")
          .startOf("day")
          .unix(),
        moment__lte: moment()
          .startOf("day")
          .unix(),
        by_card: true,
        card_code: this.$store.getters.getPageCardsList.code,
        controller__object: this.$store.getters.getPageCardsList.object
      }
    });
    this.$store.commit("SET_VALUE_SORT", "moment");
    this.isDateLoaded = true;
    this.$store.dispatch("fetchPassLog").finally(() => {
      this.isDateLoaded = false;
    });
  },

  computed: {
    header() {
      return tableColsPassageLog.filter(
        el =>
          el.text !== "ФИО" &&
          el.text !== "Пользователь" &&
          el.text !== "Комментарий"
      );
    },
    getPreQuery() {
      return this.$store.getters.getPreQuery;
    },
    sourceObject() {
      return this.$store.getters.getPassLog;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id,
        moment__gte: moment()
          .startOf("day")
          .unix(),
        moment__lte: moment()
          .add(1, "days")
          .startOf("day")
          .unix()
      };
    }
  },

  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...to.query,
            moment__gte: this.urlQuery.query.moment__gte
              ? this.urlQuery.query.moment__gte
              : moment()
                  .startOf("day")
                  .unix(),
            moment__lte: this.urlQuery.query.moment__lte
              ? this.urlQuery.query.moment__lte
              : moment()
                  .add(1, "days")
                  .startOf("day")
                  .unix()
          }
        });
        this.isDateLoaded = true;
        this.$store.dispatch("fetchPassLog").finally(() => {
          this.isDateLoaded = false;
        });
      }
    }
  },
  methods: {
    todayLogsInfo(data) {
      let today = moment(new Date()).format("DD.MM.YYYY");
      let filter = data?.results
        .map(function(elem) {
          return {
            data: moment((elem?.moment || 0) * 1000).format("DD.MM.YYYY"),
            device: elem.card_code != null ? "Карта" : "Приложение"
          };
        })
        .filter(function(elem) {
          return elem.data == today;
        });
      let result = {
        logsCounts: filter.length,
        phoneCounts: filter.filter(elem => elem.device == "Приложение").length,
        cardCounts: filter.filter(elem => elem.device == "Карта").length
      };
      return result;
    },

    downloadList() {
      this.loadingBtn = true;
      this.$store.dispatch("downloadPassLog", this.header).then(() => {
        this.loadingBtn = false;
      });
    }
  }
};
</script>

<style scoped>
.form__avatar {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid #d0d1d9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.form__avatar-card {
  background-image: url("/img/icons/credit-card.svg");
  width: 20px;
  height: 20px;
  background-size: contain;
}

.form__avatar-phone {
  background-image: url("/img/icons/smartphone.svg");
  width: 20px;
  height: 20px;
  background-size: contain;
}
.title-loading {
  margin-bottom: 16px;
  margin-top: 8px;
  text-align: center;
}
.sub-title {
  margin-left: 4px;
}
</style>
